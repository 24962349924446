import React from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade';

import TriosShape from "../../static/technologies/3shape.png"
import OwandyCam from "../../static/technologies/owandy-cam.jpg"
import ArtexArcoFacciale from "../../static/technologies/artex-arco-facciale.png"
import EndoMate from "../../static/technologies/endo-mate-dt-nsk.png"
import MyRayZenX from "../../static/technologies/myRay-zen-x.png"
import SurgeryPlus from "../../static/technologies/surgery-plus-de-giorgi.jpg"
import AirFlow2 from "../../static/technologies/air-flow-2-ems.png"
import PoliluxAccelerator from "../../static/technologies/polilux-accelerator-plus.jpg"
import Newton from "../../static/technologies/newtom.png"
import MGuide from "../../static/technologies/mGuide.jpg"
import AirFlowProphylaxis from "../../static/technologies/airflow-prophylaxis.png"
import KavoDiagnocam from "../../static/technologies/kavo-diagnocam.jpg"
import PrimaMu from "../../static/technologies/microscopio_prima_mu_odontoiatrico.png"
import HandyPro from "../../static/technologies/handy-pro.png"
import MasterFlux from "../../static/technologies/master-flux.jpg"

const WhiteRow = styled.div`
  position: relative;
  color: ${({ theme }) => theme.text};
  height: 400px;
  @media (max-width: 767px) {
    height: unset;
    position: unset;
    margin: 40px 0;
  }
  .wrapper-text {
    position: absolute;
    left: 0;
    width: 50%;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 5vw;
    @media (max-width: 767px) {
      position: unset;
      transform: unset;
      width: 100vw;
      padding: 30px 5vw;
    }
    h2 {
      font-weight: 300;
      font-size: 34px;
    }
  }
  .wrapper-image {
    position: absolute;
    left: 50%;
    width: 50%;
    height: 400px;
    @media (max-width: 767px) {
      position: unset;
      width: 100vw;
      height: unset;
    }
    img {
      max-width: 350px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 767px) {
        max-height: 200px;
        display: block;
        margin: auto;
        position: unset;
        transform: unset;
      }
    }
  }
`;

const ColorRow = styled.div`
  position: relative;
  color: ${({ theme }) => theme.white};
  background: linear-gradient(90deg,rgba(65,236,241,1) 0%,rgba(49,104,155,1) 100%);
  height: 400px;
  @media (max-width: 767px) {
    height: unset;
    position: unset;
    margin: 40px 0;
  }
  .wrapper-text {
    position: absolute;
    left: 50%;
    width: 50%;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 5vw;
    @media (max-width: 767px) {
      position: unset;
      transform: unset;
      width: 100vw;
      padding: 30px 5vw;
    }
    h2 {
      font-weight: 300;
      font-size: 34px;
    }
  }
  .wrapper-image {
    position: absolute;
    right: 50%;
    width: 50%;
    height: 400px;
    @media (max-width: 767px) {
      position: unset;
      width: 100vw;
      height: unset;
    }
    img {
      max-height: 350px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 767px) {
        max-height: 200px;
        display: block;
        margin: auto;
        position: unset;
        transform: unset;
      }
    }
  }
`;

export default function Apparecchiature() {
  return (
    <Layout pageTitle="Tecnologie - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
      <Fade left duration={1000} >
        <h1>Le Nostre Tecnologie</h1>
      </Fade>
      <WhiteRow>
        <div className="wrapper-text">
          <Fade left delay={500}>
            <h2>Fotocamera intraorale</h2>
            <p>
            La Fotocamera intraorale è uno strumento di comunicazione che permette di condividere con i pazienti, in modo semplice ed immediatolo stato di salute della loro bocca. Il manipolo non è fastidioso in bocca e grazie ai 6 LED dell’endoscopio assicura immagini chiare e ad alto contrasto. Le immagini vengono salvate nella cartella del paziente per future consultazioni.
            </p>
          </Fade>
        </div>
        <Fade right delay={800}>
          <div className="wrapper-image">
            <img src={OwandyCam} alt="Owandycam"/>
          </div>
        </Fade>
      </WhiteRow>
      <ColorRow>
        <div className="wrapper-text">
          <Fade right delay={500}>
            <h2>Lo scanner intraorale</h2>
            <p>
              Lo Studio Dentistico Maucieri si avvale di questa tecnologia per la realizzazione delle impronte, eliminando qualsiasi scomodo per i pazienti. Questo macchinario infatti grazie ad una tecnologia molto complessa riesce a rilevare l’impronta di entrambe le arcate in pochissimi secondi (25” per l’impronta di un quadrante).
            </p>
          </Fade>
        </div>
        <Fade left delay={800}>
          <div className="wrapper-image">
          <img src={TriosShape} alt="Trios 3 shape"/>
          </div>
        </Fade>
      </ColorRow>
      <WhiteRow>
        <div className="wrapper-text">
          <Fade left delay={500}>
            <h2>Chirurgia Computerizzata</h2>
            <p>
              L’implantologia guidata rappresenta l’ultima frontiera chirurgica in odontoiatria e lo Studio Dentistico Dr.Maucieri ne è dotato.  Nell’implantologia computer guidata è il software che analizza l’osso è permette di sfruttarne anche i più minimi volumi con una precisione incredibile.
            </p>
          </Fade>
        </div>
        <Fade right delay={800}>
          <div className="wrapper-image">
            <img src={MGuide} alt="M guide"/>
          </div>
        </Fade>
      </WhiteRow>
      <ColorRow>
        <div className="wrapper-text">
          <Fade right delay={500}>
            <h2>AirFlow</h2>
            <p>
              Il Metodo Originale AIR-FLOW, sviluppato da EMS, utilizza una miscela di aria, polvere e acqua per tutta una serie di trattamenti dentali preventivi e restaurativi. Trattamento estremamente affidabile e confortevolo per i pazienti.
            </p>
          </Fade>
        </div>
        <Fade left delay={800}>
          <div className="wrapper-image">
            <img src={AirFlow2} alt="Air flow 2"/>
          </div>
        </Fade>
      </ColorRow>
      <WhiteRow>
        <div className="wrapper-text">
          <Fade left delay={500}>
            <h2>Lampada per lo sbiancamento professionale</h2>
            <p>
              Polilux Accelerator Plus è una lampada a LED specifica per lo sbiancamento professionale alla poltrona: la sua lunghezza d’onda (465 nm)e la sua fonte di energia (30.000 mW/cm2) sono state studiate per ottenere il migliore risultato possibile in una sola seduta di venti minuti. La lampada ha uno speciale schermo che permette l’assorbimento della luce ultravioletta ed infrarossa evitando il riscaldamento della polpa e delle mucose.
            </p>
          </Fade>
        </div>
        <Fade right delay={800}>
          <div className="wrapper-image">
            <img src={PoliluxAccelerator} alt="polilux accellerator"/>
          </div>
        </Fade>
      </WhiteRow>
      <ColorRow>
        <div className="wrapper-text">
          <Fade right delay={500}>
            <h2>Sensore radiografico intraorale</h2>
            <p>
              Il Nostro sistema radiografica ZEN-X adotta un sensore a tre strati altamente sofisticato, in grado di acquisire immagini ad elevata risoluzione con dosi radiogene minime. Con ZEN-X l’intero procedimento di acquisire immagini radiografiche diventa più semplice, grazie alla straordinaria ergonomia delle forme, che assicurano il massimo agio per il paziente. Gli spigoli smussati e gli angoli arrotondati si adattano confortevolmente alla forma anatomica del cavo orale, semplificando il posizionamento del sensore.
            </p>
          </Fade>
        </div>
        <Fade left delay={800}>
          <div className="wrapper-image">
            <img src={MyRayZenX} alt="My ray zenx"/>
          </div>
        </Fade>
      </ColorRow>
      <WhiteRow>
        <div className="wrapper-text">
          <Fade left delay={500}>
            <h2>Elettrobisturi elettronico</h2>
            <p>
              Elettrobisturi elettronico ad alta frequenza adatto per chirurgia monopolare dentale.
            </p>
          </Fade>
        </div>
        <Fade right delay={800}>
          <div className="wrapper-image">
            <img src={SurgeryPlus} alt="Surgey plus"/>
          </div>
        </Fade>
      </WhiteRow>
      <ColorRow>
        <div className="wrapper-text">
          <Fade right delay={500}>
            <h2>Micromotore per endodonzia</h2>
            <p>
              Il Nostro Endo Mate DT è uno strumento utilizzato nelle terapie canalari, è affidabile e pratico da usare.Il manipolo leggero, maneggevole ed ergonomico, permette di lavorare in piena comodità senza disagi per i pazienti. Ottima visibilità durante i trattamenti. Permette una rapida visualizzazione dei dati e dei valori impostati permettendo al clinico di controllare in maniera immediata i valori impostati e l’andamento operativo.
            </p>
          </Fade>
        </div>
        <Fade left delay={800}>
          <div className="wrapper-image">
            <img src={EndoMate} alt="Endo mate"/>
          </div>
        </Fade>
      </ColorRow>
      <WhiteRow>
        <div className="wrapper-text">
          <Fade left delay={500}>
            <h2>Arco facciale Artex</h2>
            <p>
              L’arco facciale Artex permette lo studio anatomicamente corretto della relazione cranio-assiale. Nella diagnostica funzionale e nella terapia rappresenta uno strumento indispensabile.
            </p>
          </Fade>
        </div>
        <Fade right delay={800}>
          <div className="wrapper-image">
            <img src={ArtexArcoFacciale} alt="Artex arco facciale"/>
          </div>
        </Fade>
      </WhiteRow>
      <ColorRow>
        <div className="wrapper-text">
          <Fade right delay={500}>
            <h2>Tac odontoiatrica</h2>
            <p>
              Nello Studio Dentistico Maucieri è presente il NewTom Giano, macchinario per la diagnostica radiologica odontoiatrica e maxillo-facciale che sfrutta la tecnologia Cone Beam rendendo disponibili ricostruzioni 3D delle strutture anatomiche esaminate.
            </p>
          </Fade>
        </div>
        <Fade left delay={800}>
          <div className="wrapper-image">
            <img src={Newton} alt="Newton"/>
          </div>
        </Fade>
      </ColorRow>
      <WhiteRow>
        <div className="wrapper-text">
          <Fade left delay={500}>
            <h2>Microscopio Odontoiatrico</h2>
            <p>
           Il Microscopio Labomed PRIMA Mu consente una migliore visualizzazione nei dettagli dei particolari anatomici e delle strutture dei tessuti. Utilizzando ingrandimenti maggiori e quindi vedendo di più, gli odontoiatri possono applicare le proprie competenze con più precisione e ottimizzare anche il proprio flusso di lavoro.
            </p>
          </Fade>
        </div>
        <Fade right delay={800}>
          <div className="wrapper-image">
            <img src={PrimaMu} alt="Prima MU Microscopio Odontoiatrico"/>
          </div>
        </Fade>
      </WhiteRow>
      <ColorRow>
        <div className="wrapper-text">
          <Fade right delay={500}>
            <h2>AirFlow Prophylaxis Master</h2>
            <p>
            AirFlow Prophylaxis Master è l’ultima innovazione ﬁrmata EMS per la Guided Bioﬁlm Therapyuna, una soluzione unica per la prevenzione e la proﬁlassi di carie e parodontiti. Progettato per un uso professionale intensivo, garantisce i più alti standard in termini di prestazioni, sicurezza e comfort, essendo stato speciﬁcamente prodotto per offrire i tre trattamenti principali della Guided Biofilm Therapy.
            </p>
          </Fade>
        </div>
        <Fade left delay={800}>
          <div className="wrapper-image">
            <img src={AirFlowProphylaxis} alt="Newton"/>
          </div>
        </Fade>
      </ColorRow>
      <WhiteRow>
        <div className="wrapper-text">
          <Fade left delay={500}>
            <h2>Rivelatore di carie</h2>
            <p>
            La DIAGNOcam è un nuovo ed innovativo strumento diagnostico che sfrutta la struttura del dente come foto-conduttore senza alcuna emissione di raggi X. Semplice e pratico da utilizzare, permette di fare diagnosi di carie interprossimale precocemente ed è estremamente efficace nella comunicazione con voi pazienti, grazie alle immagini rilevate dalla telecamera e visibili sui monitor dei nostri PC.
            </p>
          </Fade>
        </div>
        <Fade right delay={800}>
          <div className="wrapper-image">
            <img src={KavoDiagnocam} alt="Kavo Diagnocam"/>
          </div>
        </Fade>
      </WhiteRow>
      <ColorRow>
        <div className="wrapper-text">
          <Fade right delay={500}>
            <h2>Laser a diodi</h2>
            <p>
            Il laser Handy pro è un laser a diodi che permette il trattamento di molteplici patologie del cavo orale, sia sui tessuti duri che molli. Rispetto alle tecnologie tradizionali, permette di operare con un minor numero di sedute e assicura trattamenti più veloci. Ideale per i pazienti di ogni età.
            </p>
          </Fade>
        </div>
        <Fade left delay={800}>
          <div className="wrapper-image">
            <img src={HandyPro} alt="Handy Pro"/>
          </div>
        </Fade>
      </ColorRow>
      <WhiteRow>
        <div className="wrapper-text">
          <Fade left delay={500}>
            <h2>Sedazione Cosciente</h2>
            <p>
            La Master Flux Plus è l’unica apparecchiatura per sedazione interamente studiata e realizzata in Italia. E’ un prodotto di alta precisione e sicuro per il paziente. La macchina presenta una mascherina che non lascia segni e si conforma perfettamente al vostro viso. La sedazione avviene in modo semplice e veloce, tramite una regolazione manuale dell’ossigeno e l’ossido di diazoto (O2 e N2O).
            </p>
          </Fade>
        </div>
        <Fade right delay={800}>
          <div className="wrapper-image">
            <img src={MasterFlux} alt="Master Flux Plus Sedazione Cosciente"/>
          </div>
        </Fade>
      </WhiteRow>
    </Layout>
  )
}